<template>
  <section>
    <top-alternative-section :image="PartnersImage" :title="$t('partners.topAlternative.title')"
      :content="$t('partners.topAlternative.content')"></top-alternative-section>

    <partners-section></partners-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import PartnersImage from "../../assets/meeting.png"
import PartnersSection from "../sections/PartnersSection.vue";

export default {
  components: {
    TopAlternativeSection,
    PartnersSection,
    ContactSection
  },
  data() {
    return {
      PartnersImage: PartnersImage
    };
  }, 
  metaInfo: {
    title: 'Socios',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Wellcom ha consolidado fuertes alianzas con distintas organizaciones con el propósito de proveer mejores soluciones integrales a sus clientes, ya que parte de nuestra estrategia corporativa es establecer relaciones bajo el enfoque de Ganar-Ganar.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
};
</script>
